<template>
  <b-modal
    id="edit-sales-plan-executions-modal"
    size="lg"
    :title="$t('reports.salesPlanFulfillment')"
    :ok-disabled="$v.$invalid || salesPlanExecutionsStatus === 'loading'"
    :ok-title="$t('table.save')"
    :cancel-title="$t('table.cancel')"
    @ok.prevent="saveData()"
    @show="loadData()"
  >
    <SpinnerLoader v-if="salesPlanExecutionsStatus !== 'success'" :loading="salesPlanExecutionsStatus" />
    <div v-else>
      <form @submit.stop.prevent="checkIfValidThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <table class="table table-sm small table-hover ws-nowrap mb-0 sticky-headers">
          <thead>
            <tr>
              <th rowspan="2">{{ $t('table.period') }}</th>
              <th rowspan="2" name="prime">{{ $t('table.time') }}</th>
              <th colspan="2">wGRP {{ $t('table.open') }}</th>
              <th>wGRP {{ $t('reports.soldOut') }}</th>
              <th>{{ $t('table.budget') }}</th>
            </tr>
            <tr>
              <th>{{ $t('table.commitment') }}</th>
              <th>{{ $t('table.plan') }}</th>

              <th>{{ $t('table.commitment') }}</th>

              <th>{{ $t('table.commitment') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in monthsData">
              <tr :key="index + '_1'">
                <td name="period">{{ months[index] }}</td>
                <td>{{ $t('channelModal.prime') }}</td>

                <td class="text-right">
                  <currency-input
                    v-model="item.prime_wgrp_open_commitment"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>
                <td class="text-right">
                  <currency-input
                    v-model="item.prime_wgrp_open_plan"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>

                <td class="text-right">
                  <currency-input
                    v-model="item.prime_wgrp_sold_commitment"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>

                <td class="text-right">
                  <currency-input
                    v-model="item.prime_budget_commitment"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>
              </tr>

              <tr :key="index + '_2'">
                <td name="period">{{ months[index] }}</td>
                <td>{{ $t('channelModal.offPrime') }}</td>

                <td class="text-right">
                  <currency-input
                    v-model="item.off_prime_wgrp_open_commitment"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>
                <td class="text-right">
                  <currency-input
                    v-model="item.off_prime_wgrp_open_plan"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>

                <td class="text-right">
                  <currency-input
                    v-model="item.off_prime_wgrp_sold_commitment"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>

                <td class="text-right">
                  <currency-input
                    v-model="item.off_prime_budget_commitment"
                    required
                    class="form-control-sm"
                    :disabled="
                      broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[index] ? broadcastClosedMonths[index].is_closed : false
                    "
                  />
                </td>
              </tr>

              <tr :key="index + '_3'" class="total-row">
                <th name="period">{{ months[index] }}</th>
                <th>{{ $t('booking.total') }}</th>

                <th class="text-right">
                  {{ ((+item.prime_wgrp_open_commitment || 0) + (+item.off_prime_wgrp_open_commitment || 0)) | toFixedAndSpace }}
                </th>
                <th class="text-right">
                  {{ ((+item.prime_wgrp_open_plan || 0) + (+item.off_prime_wgrp_open_plan || 0)) | toFixedAndSpace }}
                </th>

                <th class="text-right">
                  {{ ((+item.prime_wgrp_sold_commitment || 0) + (+item.off_prime_wgrp_sold_commitment || 0)) | toFixedAndSpace }}
                </th>

                <th class="text-right">
                  {{ ((+item.prime_budget_commitment || 0) + (+item.off_prime_budget_commitment || 0)) | toFixedAndSpace }}
                </th>
              </tr>
            </template>
          </tbody>
        </table>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import { required, minValue } from 'vuelidate/lib/validators';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
//import MultiSelect from '@/components/MultiSelect';
import CurrencyInput from '@/components/CurrencyInput';

export default {
  name: 'ModalEditSalesPlanExecutions',
  components: { SpinnerLoader, /*MultiSelect,*/ CurrencyInput },
  filters: { toFixedAndSpace },
  model: {
    prop: 'selects',
    event: 'onSelect',
  },
  props: {
    selects: { type: Object, default: undefined },
  },
  data() {
    return {
      monthsData: [
        {
          month: 1,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 2,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 3,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 4,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 5,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 6,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 7,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 8,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 9,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 10,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 11,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 12,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      salesPlanExecutionsStatus: 'getReportSalesPlanExecutionsStatus',
      salesPlanExecutions: 'getReportSalesPlanExecutions',
      commercialType: 'getCommercialType',
      channels: 'getChannelsList',
      yearsList: 'getYear',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      broadcastClosedMonths: 'getBroadcastClosedMonths',
    }),
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
  },
  validations() {
    return {
      monthsData: {
        $each: {
          prime_wgrp_open_commitment: { minValue: minValue(0), required },
          prime_wgrp_open_plan: { minValue: minValue(0), required },
          prime_wgrp_sold_commitment: { minValue: minValue(0), required },
          prime_budget_commitment: { minValue: minValue(0), required },
          off_prime_wgrp_open_commitment: { minValue: minValue(0), required },
          off_prime_wgrp_open_plan: { minValue: minValue(0), required },
          off_prime_wgrp_sold_commitment: { minValue: minValue(0), required },
          off_prime_budget_commitment: { minValue: minValue(0), required },
        },
      },
    };
  },
  methods: {
    async loadSalesPlanExecution() {
      // Load closed months for disabling inputs
      if (this.$checkPermissions('broadcasting.closed_months'))
        await this.$store.dispatch('GET_BROADCAST_CLOSED_MONTHS', { data: { year: +this.selects.year?.id } });
      if (!this.salesPlanExecutions.data) {
        await this.$store.dispatch('GET_REPORT_SALES_PLAN_EXECUTIONS', {
          'filter[year_id]': this.selects.year?.id,
          'filter[channel_id]': this.selects.channel?.id,
          'filter[commercial_type_id]': this.selects.commercial?.id,
          'filter[target_audience_id]': this.selects.target?.id,
        });
      } else {
        this.salesPlanExecutions?.data?.[this.selects.channels[0]?.name]?.items.forEach((el, index) => {
          if (index <= 12 && el?.month === this.monthsData[index]?.month) {
            this.monthsData[index].prime_wgrp_open_commitment = (+el.prime_commitment_wgrp_open).toFixed(2) || 0;
            this.monthsData[index].prime_wgrp_open_plan = (+el.prime_plan_wgrp_open).toFixed(2) || 0;
            this.monthsData[index].prime_wgrp_sold_commitment = (+el.prime_commitment_wgrp_sold).toFixed(2) || 0;
            this.monthsData[index].prime_budget_commitment = (+el.prime_commitment_budget).toFixed(2) || 0;
            this.monthsData[index].off_prime_wgrp_open_commitment = (+el.off_prime_commitment_wgrp_open).toFixed(2) || 0;
            this.monthsData[index].off_prime_wgrp_open_plan = (+el.off_prime_plan_wgrp_open).toFixed(2) || 0;
            this.monthsData[index].off_prime_wgrp_sold_commitment = (+el.off_prime_commitment_wgrp_sold).toFixed(2) || 0;
            this.monthsData[index].off_prime_budget_commitment = (+el.off_prime_commitment_budget).toFixed(2) || 0;
          }
        });
      }
      this.$emit('updateQuery');
    },
    async loadData() {
      await this.loadSalesPlanExecution();
    },
    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.saveData();
    },
    async saveData() {
      const formData = {
        year_id: this.selects.year?.id,
        channel_id: this.selects.channels[0]?.id,
        commercial_type_id: this.selects.commercial?.id,
        target_audience_id: this.selects.target?.id,
        data: this.monthsData,
      };
      await this.$store.dispatch('PUT_CALENDAR_PLAN', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.saved'),
          });
          this.$bvModal.hide('edit-sales-plan-executions-modal');
          this.clearFields();
          this.$emit('refreshReport');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    clearFields() {
      this.monthsData = [
        {
          month: 1,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 2,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 3,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 4,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 5,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 6,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 7,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 8,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 9,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 10,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 11,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
        {
          month: 12,
          prime_wgrp_open_commitment: '',
          prime_wgrp_open_plan: '',
          prime_wgrp_sold_commitment: '',
          prime_budget_commitment: '',
          off_prime_wgrp_open_commitment: '',
          off_prime_wgrp_open_plan: '',
          off_prime_wgrp_sold_commitment: '',
          off_prime_budget_commitment: '',
        },
      ];
    },
  },
};
</script>

<style lang="sass">
#edit-sales-plan-executions-modal > .modal-dialog
  table
    width: auto
    tr th
      padding: 0 4px
    td,
    tbody th
      padding: 0 0 0 1px
    td:first-child,
    td:nth-child(2),
    tbody th:first-child
      padding: 0 4px
      vertical-align: middle

    input
      text-align: right
      width: 150px
      border-radius: 0
      -moz-appearance: textfield
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    .total-row
      background-color: hsl(240,6%,90%)

[data-color-scheme="dark"] #edit-sales-plan-executions-modal > .modal-dialog
  table
    .total-row
      background-color: hsl(221,14%,33%)
</style>
